/* eslint no-eval: 0 */

import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import { Button, Icon, Header, Segment, Modal } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getToken, getKeyUsuario } from '../../servicos/autenticacao';
import saveIcon from '../../img/save_icon.png';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../../_actions';

var request = require("request");

class Pdv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false, 
            data: [], 
            excluir:[], 
            loadingAdicionar: false, 
            loadingExportar: false, 
            loadingRecarregar: false, 
            options: { movableRows: true, height:'450px' }, 
            revendas: [],
            filialUsuario: ''};
    }
    
     open(e, item){ this.setState({ open: true , excluir:item._cell.row.data})};
     close(e, item){ this.setState({ open: false , excluir:[]})};
     
    count = function(values, data, calcParams) {
        var calc = data.length;
        return "TOTAL DE PDVs: " + calc;
    }

    exportar() {
        window.open("http://inteligenciaindustrial.com:4000/choppsystem/excel/");
    }

    adicionar() {
        this.setState({ loadingAdicionar: true });
        const { clickMenu } = this.props;
        let { filialUsuario } = this.state;
        
        
        let filial = filialUsuario === "CORPORATIVO" || filialUsuario === undefined ? "" : filialUsuario;
        let data = this.state.data;

        data.unshift({ img: saveIcon })

        this.setState({ data: data })

        var options = {
            method: 'POST',
            url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            body: { key_usuario: getKeyUsuario(), revenda: filial },
            json: true
        };

        request(options, function(error, response, body) {
            if (body.mensagem === undefined) {
                this.consultaItens()
                this.setState({ loadingAdicionar: false })
                NotificationManager.info('Novo Item Adicionado');
            } else {
                this.setState({ loadingAdicionar: false });
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }
        }.bind(this))
    }

    consultaItens() {
        this.setState({ loadingRecarregar: true });
        const { clickMenu } = this.props;
        const { filialUsuario } = this.state;

        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            if (body.mensagem === undefined) {
                let data = []
                if (filialUsuario === "CORPORATIVO" || filialUsuario === '' || filialUsuario === undefined ) {
                    data = response.body;
                } else {
                    data = response.body.filter(function (x) {
                        return x.revenda === filialUsuario
                    })
                }
                for (var g = 0; g < data.length; g++) {
                    data[g].img = saveIcon
                }
    
                this.setState({ data: data })
                NotificationManager.info('Dados Recarregados');
                this.setState({ loadingRecarregar: false })
            } else {
                this.setState({ loadingRecarregar: false })
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }

        }.bind(this));

       


    }

    salvar(e, item) {
        const { clickMenu } = this.props;
        item._cell.row.data.key_usuario = getKeyUsuario();
        if (item._cell.row.data.key === undefined) {
            var options = {
                method: 'POST',
                url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: item._cell.row.data,
                json: true
            };

            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    this.consultaItens()
                } else {
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            }.bind(this))
        }
        else {
            let options = {
                method: 'PUT',
                url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: item._cell.row.data,
                json: true
            };

            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    NotificationManager.success('Informações Salvas', '');
                } else {
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            })
        }

    }
 
    excluir(){
        let Excluir = this.state.excluir;
        Excluir.key_usuario = getKeyUsuario();
        
        const { clickMenu } = this.props;
           let options = {
                method: 'DELETE',
                url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: this.state.excluir,
                json: true
            };

            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    this.setState({open: false});
                    NotificationManager.error('Item Excluído', '');
                    this.consultaItens();
                } else {
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            }.bind(this))
        
    }

    componentWillMount() {
        let revendas = [];
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/revendas',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            this.setState({
                revendas: data
            });
            
            var options = {
                method: 'GET',
                url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/' + getKeyUsuario(),
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                json: true
            };
            
            request(options, function(error, response, body){
                this.setState({
                    filialUsuario: body[0].filial
                })
                this.consultaItens()
            }.bind(this));
        }.bind(this));

    }

    render() {
        var data = this.state.data;
        let listaRevendas = [];
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/revendas',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                listaRevendas[g] = data[g].descricao
            }
        });

        var saveIcon = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="save icon"></i>';
        };
        
         var deleteIcon = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="close icon"></i>';
        };

        const columns = [
            { title: "CÓDIGO", field: "codigo", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input",topCalc: this.count, width: 200 },
            { title: "RAZÃO SOCIAL", field: "razao_social", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input" },
            { title: "NOME FANTASIA", field: "nome_fantasia", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input" },
            { title: "CIDADE", field: "cidade", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input" },
            { title: "ESTADO", field: "estado", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "select", editorParams: { values: ["AC","AL","AP","AM","BA","CE","DF","ES","GO","MA","MT","MS","MG","PA","PB","PR","PE","PI","RJ","RN","RS","RO","RR","SC","SP","SE","TO"]}, width:80 },
            { title: "RESPONSÁVEL", field: "responsavel", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input" },
            { title: "TELEFONE", field: "telefone", align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor: "input" },
            { title: "ENDEREÇO", field: "endereco", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input" },
            { title: "CNPJ", field: "cnpj", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input" },
            { title: "REVENDA", field: "revenda", align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor: "autocomplete", editorParams: { values: listaRevendas }},
            { title: "", align: "center", cellClick: this.salvar.bind(this), formatter: saveIcon, width: 40 },
            { title: "", align: "center", cellClick: this.open.bind(this), formatter: deleteIcon, width: 40 }

        ];

        return (
        <Segment  style={{'padding':'0px', 'margin':'14px'}}> 
        <Segment clearing attached='top' style={{'padding':'10px'}}>
          <Header as='h4' floated='left' style ={{'margin':'0px'}}>
             PDV's
          </Header>

          <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  labelPosition='left' onClick={this.adicionar.bind(this)} loading={this.state.loadingAdicionar}><Icon name='plus' />Adicionar PDV</Button>
          <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  labelPosition='left' onClick={this.consultaItens.bind(this)} loading={this.state.loadingRecarregar}><Icon name='refresh' />Recarregar</Button>  
          <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  onClick={this.exportar}><Icon name='file excel' loading={this.state.loadingExportar} /></Button>
         
        </Segment>
        <Segment attached='bottom' style={{'padding':'0px', margin:'0px'}}>
         <ReactTabulator 
           data={data}
           style={{margin:'0px', fontSize:'9px','border':'0px'}}
           options={this.state.options}
           className={'very compact celled'}
           columns={columns}
           tooltips={true}
           layout={"fitColumns"}
           />
        </Segment>
        <NotificationContainer/>

        <Modal open={this.state.open} basic size='small'>
            <Header icon='delete' content='Confirmação de Exclusão' />
            <Modal.Content>
              <p>
                Deseja realmente excluir este item?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' inverted onClick={this.close.bind(this)}>
                <Icon name='remove' /> Não Excluir
              </Button>
              <Button color='green' inverted onClick={this.excluir.bind(this)}>
                <Icon name='checkmark' /> Excluir
              </Button>
            </Modal.Actions>
          </Modal>
      </Segment>
        );
    }
}


const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Pdv);
