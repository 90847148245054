/* eslint no-eval: 0 */

import React from 'react';

import { Segment, Form, Input, Button, Icon } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getToken, getKeyUsuario } from '../../servicos/autenticacao';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../../_actions';

var request = require("request");

class AlterarSenha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            senha1: '',
            senha2: ''
        };
    }
    
    componentDidMount() {
        this.consultaItens();
    }
    
    consultaItens() {
        this.setState({ loadingRecarregar: true });
        const { clickMenu } = this.props;
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/'+getKeyUsuario(),
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };
        
        request(options, function(error, response, body) {
            console.log(body);
        }.bind(this));
    }
    
    handleChange(event) {
        let opc = event.target.id, value = event.target.value;
        this.setState({
            [opc]: value
        })
    }
    
    salvar() {
        const { senha1, senha2 } = this.state;
        if (senha1 !== senha2 || senha1.length < 8 || senha2.length < 8) {
            NotificationManager.error('Senhas precisam ser iguais e ter no mínimo 8 caracteres', '');
        } else {
            var options = {
                method: 'PUT',
                url: 'https://inteligenciaindustrial.com/choppsystem/usuarios',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: { key:getKeyUsuario(), senha: senha1, alterarsenha: true },
                json: true,
            };
            
            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    this.setState({senha1: '',senha2: ''});
                    NotificationManager.success('Senha alterada com sucesso', '');
                } else {
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            }.bind(this));
        }
        
    }
    
    render() {
        return(
            <Segment style={{width: 'auto', maxWidth: '650px', margin: 'auto'}}>
            <NotificationContainer/>
                <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='Digite uma nova Senha'
                        type='password'
                        id = "senha1"
                        value = {this.state.senha1}
                        onChange={this.handleChange.bind(this)}
                      />
                      <Form.Input
                        fluid
                        label='Digite a senha novamente para confirmá-la'
                        type='password'
                        id = "senha2"
                        value = {this.state.senha2}
                        onChange={this.handleChange.bind(this)}
                      />
                    </Form.Group>
                    
                    <Button onClick={this.salvar.bind(this)} icon labelPosition='right' style={{backgroundColor:'rgb(0,102,102)', color:'white'}} fluid size='large'>
                        Salvar
                        <Icon name='sign-in' />
                    </Button>
                </Form>
            </Segment>
        )
    }
}

const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenha);