/* eslint no-eval: 0 */

import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator';
import { Segment, Header, Button, Icon, Modal } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getToken, getKeyUsuario } from '../../servicos/autenticacao';
import saveIcon from '../../img/save_icon.png';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../../_actions';

var request = require("request");

class Usuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false, data: [], excluir:[], loadingAdicionar: false, 
            loadingExportar: false, loadingRecarregar: false, options: { movableRows: true, height:'450px' },
            perfisUsuario: []
        };
    }
    
    open(e, item){ this.setState({ open: true , excluir:item._cell.row.data})};
    close(e, item){ this.setState({ open: false , excluir:[]})};
    
    count = function(values, data, calcParams) {
        var calc = data.length;
        return "TOTAL DE EQUIPAMENTOS: " + calc;
    }
    
    gerarSenha() {
        return Math.random().toString(36).slice(-8);
    }
    
    adicionar() {
        this.setState({ loadingAdicionar: true })
        const { clickMenu } = this.props;
        let data = this.state.data;

        data.unshift({ img: saveIcon })

        this.setState({ data: data })

        var options = {
            method: 'POST',
            url: 'https://inteligenciaindustrial.com/choppsystem/usuarios',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            
            body: { key_usuario: getKeyUsuario() },
            json: true
        };

        request(options, function(error, response, body) {
            if (body.mensagem === undefined) {
                this.consultaItens()
                this.setState({ loadingAdicionar: false })
                NotificationManager.info('Novo Item Adicionado');
            } else {
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }
        }.bind(this))
    }
    
    consultaItens() {
        this.setState({ loadingRecarregar: true });
        const { clickMenu } = this.props;
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/usuarios',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };
        
        request(options, function(error, response, body) {
            let data = response.body;
            const { perfisUsuario } = this.state;
            data.forEach(function(x){
                if(x.perfil !== "" && x.perfil !== null){
                    let Perfil = perfisUsuario.filter(function(y){
                        return y.codigo_perfil === x.perfil
                    });
                   x.perfil = Perfil[0].nome_perfil
                }
            })
            if (body.mensagem === undefined) {
                for (var g = 0; g < data.length; g++) {
                    data[g].img = saveIcon
                }
                
                this.setState({ data: data })
                NotificationManager.info('Dados Recarregados');
                this.setState({ loadingRecarregar: false })
            } else {
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }
        }.bind(this));
    }
    
    salvar(e, item) {
        const { clickMenu } = this.props;
        const { perfisUsuario } = this.state;
        
        const Perfil = perfisUsuario.filter(function(x){
            return x.nome_perfil === item._cell.row.data.perfil
        });
        
        item._cell.row.data.perfil = Perfil[0].codigo_perfil;
        
        item._cell.row.data.key_usuario = getKeyUsuario();
        
        if(item._cell.row.data.senha === null || item._cell.row.data.senha === '' || item._cell.row.data.senha === 'null') {
            item._cell.row.data.nova_senha = this.gerarSenha();
            item._cell.row.data.redefinir = true;
        } else {
            item._cell.row.data.redefinir = false;
        }
        
        
        if (item._cell.row.data.key === undefined) {
            var options = {
                method: 'POST',
                url: 'https://inteligenciaindustrial.com/choppsystem/usuarios',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: item._cell.row.data,
                json: true
            };
            
            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    this.consultaItens();
                } else {
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            }.bind(this))
        }
        else {
            let options = {
                method: 'PUT',
                url: 'https://inteligenciaindustrial.com/choppsystem/usuarios',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: item._cell.row.data,
                json: true
            };
            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    NotificationManager.success('Informações Salvas', '');
                } else {
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            })
        }

    }
    
    redefinirSenha(e, item){
        this.setState({
            loadingRecarregar: true
        });
        const { perfisUsuario } = this.state;
        
        const Perfil = perfisUsuario.filter(function(x){
            return x.nome_perfil === item._cell.row.data.perfil
        });
        
        item._cell.row.data.perfil = Perfil[0].codigo_perfil;
        let senhaUsuario = this.gerarSenha();
        if (item._cell.row.data.email !== "" && item._cell.row.data.email !== null) {
            item._cell.row.data.img = "";
            item._cell.row.data.nova_senha = senhaUsuario
            let options = {
                method: 'PUT',
                url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/redefinirsenha',
                headers: {'content-type': 'application/json',
                          'x-access-token': getToken()},
                body: item._cell.row.data,
                json: true
            };
            
            request(options, function(error, response, body) {
                if (body.mensagem === undefined) {
                    this.setState({
                        loadingRecarregar: false
                    });
                    NotificationManager.success(body.resultado);
                } else {
                    this.setState({
                        loadingRecarregar: false
                    });
                    NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                    localStorage.clear();
                    setInterval(function(){ clickMenu('') }, 6000);
                }
            }.bind(this));
        } else {
            this.setState({
                loadingRecarregar: false
            });
            NotificationManager.error("Erro ao tentar redefinir senha. Preencha o campo E-mail para redefinir a senha");
        }
        
    }
    
    excluir(){
        let Excluir = this.state.excluir;
        Excluir.key_usuario = getKeyUsuario();
        
        const { clickMenu } = this.props;
        let options = {
            method: 'DELETE',
            url: 'https://inteligenciaindustrial.com/choppsystem/usuarios',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            body: this.state.excluir,
            json: true
        };
        
        request(options, function(error, response, body) {
            if (body.mensagem === undefined) {
                this.setState({open: false});
                NotificationManager.error('Item Excluído', '');
                this.consultaItens();
            } else {
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }
        }.bind(this))
    }
    
    componentWillMount() {
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/perfis',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            this.setState({
                perfisUsuario: data
            });
            this.consultaItens();
        }.bind(this));
    }
    
    render() {
        
        let Perfis = [];
        let Filiais = [];
        let FiliaisFiltrado = [];
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/perfis',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                Perfis[g] = data[g].nome_perfil
            }
        });
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/revendas',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                if(Filiais.indexOf(data[g].descricao.toUpperCase()) === -1){
                    Filiais[g] = data[g].descricao.toUpperCase()
                }
            }
            
            Filiais.sort();
            
        });
        var data = this.state.data;
        
        var saveIcon = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="save icon"></i>';
        };
        var alterIcon = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="redo icon"></i>';
        };
        var deleteIcon = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="close icon"></i>';
        };
        
        const columns = [
            { title: "CÓDIGO", field: "codigo_usuario", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor:"input",width: 75 },
            { title: "NOME USUÁRIO", field: 'nome_usuario', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"input",width: 120},
            { title: "SOBRENOME USUÁRIO", field: 'sobrenome_usuario', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"input",width: 135},
            { title: "FUNÇÃO", field: 'funcao', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"input",width: 120},
            { title: "FILIAL", field: 'filial', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"select",width: 100, editorParams:{ values: Filiais}},
            { title: "E-MAIL", field: 'email', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"input",width: 275},
            { title: "TELEFONE", field: 'telefone', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"input", width: 100},
            { title: "USUÁRIO", field: 'usuario', align: "center", headerFilter: "input", headerFilterPlaceholder: " ", editor:"input", width: 100},
            { title: "PERFIL", field: "perfil", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor:"select", width: 160, editorParams:{ values: Perfis}},
            { title: "", align: "center", cellClick: this.redefinirSenha.bind(this), formatter: alterIcon, width: 40},
            { title: "", align: "center", cellClick: this.salvar.bind(this), formatter: saveIcon, width: 40 },
            { title: "", align: "center", cellClick: this.open.bind(this), formatter: deleteIcon, width: 40 }
        ];
        
        return (
            <Segment  style={{'padding':'0px', 'margin':'14px'}}> 
                <Segment clearing attached='top' style={{'padding':'10px'}}>
                    <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                        Usuários
                    </Header>
                    {
                    <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  labelPosition='left' onClick={this.adicionar.bind(this)} loading={this.state.loadingAdicionar}><Icon name='plus' />Adicionar Usuário</Button>
                    }
                    <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  labelPosition='left' onClick={this.consultaItens.bind(this)} loading={this.state.loadingRecarregar}><Icon name='refresh' />Recarregar</Button>  
                    {/* <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  onClick={this.exportar}><Icon name='file excel' loading={this.state.loadingExportar} /></Button> */}
                </Segment>
                <Segment attached='bottom' style={{'padding':'0px', margin:'0px'}}>
                 <ReactTabulator 
                   data={data}
                   style={{margin:'0px', fontSize:'9px','border':'0px'}}
                   options={this.state.options}
                   className={'very compact celled'}
                   columns={columns}
                   tooltips={true}
                   layout={"fitColumns"}
                   />
                </Segment>
                <NotificationContainer/>
        
                <Modal open={this.state.open} basic size='small'>
                    <Header icon='delete' content='Confirmação de Exclusão' />
                    <Modal.Content>
                      <p>
                        Deseja realmente excluir este item?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic color='red' inverted onClick={this.close.bind(this)}>
                        <Icon name='remove' /> Não Excluir
                      </Button>
                      <Button color='green' inverted onClick={this.excluir.bind(this)}>
                        <Icon name='checkmark' /> Excluir
                      </Button>
                    </Modal.Actions>
                    
                  </Modal>
            </Segment>
        )
    }
}

const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
