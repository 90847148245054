import React from 'react';
import { Menu, Button, Icon, Image, Dropdown } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import img from './../cidade_imperial_logo.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../_actions';

import { getToken, getKeyUsuario } from '../servicos/autenticacao';

var request = require("request");

class Footer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Componentes: [], Agrupadores:[]
    };
  }
  
  
  componentDidMount(){
    const { clickMenu } = this.props;
    let ArrayAgrupadores = [];
    var options = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/'+getKeyUsuario(),
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    };
    
    request(options, function(error, response, body) {
      if (body.length > 0) {
        console.log('Entrei no if do footer');
        clickMenu('Inicio');
        let codigoPerfil = body[0].perfil;
        options = {
          method: 'GET',
          url: 'https://inteligenciaindustrial.com/choppsystem/perfisrecursos/recursos/' + codigoPerfil,
          headers: {
            'content-type': 'application/json',
            'x-access-token': getToken()
          },
          json: true
        };
        
        request(options, function(error, response, body) {
          if (body.length > 0) {
            for(let i = 0; i < body.length; i++){
              if(ArrayAgrupadores.indexOf(body[i].agrupador) === -1){
                ArrayAgrupadores.push(body[i].agrupador)
              }
            }
            
            let Componente = []
            ArrayAgrupadores.forEach(function (x) {
              let elemento = `
                <Dropdown item icon='${x}'>
                  <Dropdown.Menu >
              `;
              body.forEach(function (y) {
                if(y.agrupador === x){
                  elemento += `
                    <Dropdown.Item icon='square' text='${y.nome_recurso}' onClick={() => clickMenu('${y.nome_recurso}')}/>
                  `;
                }
              });
              elemento+= `
                  </Dropdown.Menu>
                </Dropdown>
              `
              
              Componente.push(elemento);
            });
            
            this.setState({
              Componentes: body, Agrupadores: ArrayAgrupadores, Componente: Componente
            });
            
          } else {
          }
        }.bind(this));
      } else {
        NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
        localStorage.clear();
        setInterval(function(){ clickMenu('Sair') }, 6000);
      }
    }.bind(this));
  }
  
    
  render() {
    const { clickMenu, menu } = this.props;
    const {Componentes, Agrupadores, Componente} = this.state;

    return (
        <Menu fixed='bottom' style={{backgroundColor:'rgb(0,102,102)', border:'0px',minWidth: parseInt((Agrupadores.length +1) * 55)+'px', width: parseInt((Agrupadores.length +1) * 55)+'px', fontSize:'12px'}}>
        <NotificationContainer/>
        <Dropdown item icon='home large'>
          <Dropdown.Menu >
            <Dropdown.Item icon='square' text='INÍCIO' onClick={() => clickMenu('Inicio')}/>
        </Dropdown.Menu>
        </Dropdown>
        
        {
          Agrupadores.map(function(x){
          return (
            <Dropdown item icon={x}>
              <Dropdown.Menu >
              {
                  Componentes.map(function(y){
                    if (y.agrupador === x) {
                      return(
                        <Dropdown.Item icon='square' key = {y.id} text={y.nome_exibicao_recurso} 
                          onClick={() => clickMenu(y.nome_recurso !== 'Sair' ? y.nome_recurso: 'FECHAR')}/>
                      );
                    }
                  })
                  
                }
              </Dropdown.Menu>
            </Dropdown>
          )
            
          })
        }
        </Menu>
    );
  }
}

const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);
  
export default connect(mapStateToProps, mapDispatchToProps)(Footer);


