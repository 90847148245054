import React from 'react'
import { Button, Form, Grid, Image, Message, Transition, Icon, Progress, Modal, Header } from 'semantic-ui-react'
import img from './../../choppsystemlogo.png';
import logo_ccip from './../../cidade_imperial_logo.png';
import { getToken, getKeyUsuario } from '../../servicos/autenticacao';
import { getVersao } from '../../servicos/informacoes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../../_actions';


var request = require("request");

class Login extends React.Component {
  
  state = {
           visible: true, 
           avanco:0, 
           visibleprogressbar:'none',
           usuario:'',
           senha:'',
           loginMessageContent:'',
           visiblemessage:'none',
           open: false,
           email: '',
           displayMessage: 'none',
           displayContent: '',
           displayColor: '',
           loadingbutton: false
          };

  atualiza = (e, { name, value }) => this.setState({ [name]: value });
  
  open(e, item){ this.setState({ open: true })};
  close(e, item){ this.setState({ open: false })};
  
  entrar(){ 
      let usuario = this.state.usuario;
      let senha = this.state.senha;
      let data = new Date();
      let dataAtualizacao = ("0" + data.getDate()).slice(-2).toString() + "/" + 
          ("0" + (data.getMonth() + 1)).slice(-2).toString() + "/" +
          data.getFullYear().toString();
          
          let horaAtualizacao = 
          ("0" + data.getHours()).slice(-2).toString() + ":" +
          ("0" + data.getMinutes()).slice(-2) + ":" +
          ("0" + data.getSeconds()).slice(-2);
      const { clickMenu } = this.props;
      
      if(usuario.length < 2){
        this.setState({loginMessageContent : 'Preencha o campo Usuário.',visiblemessage:''});
      }else if(senha.length < 2){
        this.setState({loginMessageContent : 'Preencha o campo Senha.',visiblemessage:''});
      }else{
        
        this.setState(prevState => ({ visible: !prevState.visible, visibleprogressbar:'',visiblemessage:'none' }))
        
        let x = 0;
        
        let si = setInterval(function(){ 
          x = x+1; 
          this.setState({avanco:x});
          
          if(x === 100){
           clearInterval(si); 
          }else if(x === 75){
            
            var options_geral = { method: 'POST',
              url: 'https://inteligenciaindustrial.com/choppsystem/login',
              json: {
                "usuario":this.state.usuario,
                "senha":this.state.senha,
                "codigo_controle_versao": getVersao(),
                "dataAtualizacao": dataAtualizacao,
                "horaAtualizacao": horaAtualizacao
              },                      
              headers: {} 
            };
            
            request(options_geral, function (error, response, body) {
              
              if (body.mensagem === 'autenticacao invalida') {
                this.setState({loginMessageContent : 'Usuário ou senha inválidos!',visiblemessage:'', visibleprogressbar:'none'});
              }else if(body.mensagem === 'autenticacao valida'){
                localStorage.setItem("token", body.token);
                localStorage.setItem("keyUsuario", body.usuario.key);
                clickMenu('Inicio');
              } else{
              }
              
            }.bind(this));
          
          }
          
        }.bind(this), 25);
        
      }
  }
      handleChange(event){
        this.setState({email: event.target.value, displayMessage: 'none'});
      }
      
      recuperarSenha(){
        this.setState({displayMessage: 'none', displayColor: '', displayContent: '', loadingbutton: true});
        
        if (this.state.email.length > 3) {
          var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/email/' + this.state.email,
            headers: {
              'content-type': 'application/json'
            },
            json: true
          };
          
          request(options, function(error, response, body) {
            this.setState({
              displayMessage: '', displayContent: body.mensagem, displayColor: body.color, loadingbutton: false
            });
          }.bind(this));
        } else {
          this.setState({displayMessage: '', displayContent: 'Preencho o campo E-mail', displayColor: 'red', loadingbutton: false
          });
        }
      }
      
      render() {
       // const { clickMenu } = this.props;

        const { visible, avanco, visibleprogressbar, loginMessageContent, visiblemessage } = this.state

         return( <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ width: 350 }}>
            
              <Transition animation={'pulse'} duration={2000} visible={visible}>
                <Image size='mini' src={img} style={{ marginBottom: '20px', marginLeft: '75px', width:'175px' }} />
              </Transition>
              
              <Form size='large'>
                  <Form.Input 
                    fluid 
                    placeholder='Usuário' 
                    icon='user' 
                    iconPosition='left' 
                    onChange = {this.atualiza}
                    name='usuario'
                    style={{textAlign:'center', borderColor:'rgb(0,176,80)'}} />
                  <Form.Input
                    fluid
                    placeholder='Senha'
                    type='password'
                    onChange = {this.atualiza}
                    name='senha'
                    style={{textAlign:'center', borderColor:'rgb(0,176,80)'}} 
                    icon='lock' iconPosition='left'
                  />
                  <Button icon labelPosition='right' style={{backgroundColor:'rgb(0,102,102)', color:'white'}} fluid size='large' onClick={this.entrar.bind(this)}>
                    Entrar
                    <Icon name='sign-in' />
                  </Button>
              </Form>
              
              <Progress percent={avanco} size='tiny' color='green' active style={{display:visibleprogressbar}}/>
   
              <Message style={{fontSize:'10px'}}>
                Esqueceu usuário ou senha?<a href='#' onClick = {this.open.bind(this)}> Clique aqui!</a>
              </Message>
              
              <Message
                  style={{display:visiblemessage}}
                  negative
                  content={loginMessageContent}
                />
              <Transition animation={'pulse'} duration={2000} visible={visible}>
                <Image size='mini' src={logo_ccip} style={{  marginLeft: '111px', marginBottom: '20px', width:'100px' }} />
              </Transition>
              <p style={{fontSize:'8px'}}>{getVersao()}</p>
            </Grid.Column>
            
            <Modal open={this.state.open} onClose={this.close.bind(this)} size='tiny'>
              <Header icon='user circle outline' content='Esqueci minha senha' />
              <Modal.Content>
                <Form>
                  <Form.Field>
                    <label>E-mail</label>
                    <input placeholder='Informe seu E-mail' onChange={this.handleChange.bind(this)}/>
                  </Form.Field>
                </Form>
                <Message style = {{display: this.state.displayMessage}} color= {this.state.displayColor} closeIcon>
                  <Message.Header>{this.state.displayContent}</Message.Header>
                </Message>
              </Modal.Content>
              <Modal.Actions>
                <Button style={{backgroundColor:'rgb(0,102,102)', color:'white'}} inverted onClick={this.recuperarSenha.bind(this)} loading = {this.state.loadingbutton}>
                  <Icon name='send' /> Enviar
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid>)
      }
}

const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Login);
