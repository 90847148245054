/* eslint no-eval: 0 */

import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import { Button, Icon, Header, Segment, Modal } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getToken, getKeyUsuario } from '../../servicos/autenticacao';
import saveIcon from '../../img/save_icon.png';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../../_actions';

import 'react-notifications/lib/notifications.css';

var request = require("request");

class Equipamentos extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: [], 
            filiais: [], 
            loadingAdicionar: false, 
            loadingExportar: false, 
            loadingRecarregar: false, 
            options: { movableRows: true, height:'450px' },
            filialUsuario: '',
            displayAddRevenda: 'none'
        };
    }
    
     open(e, item){
         if (item._cell.row.data.DISABLED !== "1") {
             this.setState({ 
                 open: true , 
                 excluir:item._cell.row.data
             })
         } else {
             NotificationManager.error('Não é possivel excluir equipamentos que foram movimentados', '');
         }
     };
     close(e, item){ this.setState({ open: false , excluir:[]})};

    count = function(values, data, calcParams) {
        var calc = data.length;
        return "TOTAL DE EQUIPAMENTOS: " + calc;
    }
    
    editar = function(cell) {
        let result = false
        if (cell._cell.row.data.DISABLED !== "1") {
            result = true
        } else {
            result = false
        }
        return result
    }

    adicionar() {
        this.setState({ loadingAdicionar: true });
        let { filialUsuario } = this.state;
        let filial = filialUsuario === "CORPORATIVO" || filialUsuario === undefined ? "" : filialUsuario;
        let data = this.state.data;

        data.unshift({ img: saveIcon });

        this.setState({ data: data });

        var options = {
            method: 'POST',
            url: 'https://inteligenciaindustrial.com/choppsystem/equipamentos',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            body: { key_usuario: getKeyUsuario(), revenda: filial },
            json: true
        };

        request(options, function(error, response, body) {
            this.consultaItens();
            this.setState({ loadingAdicionar: false });
            NotificationManager.info('Novo Item Adicionado');
        }.bind(this))
    }

    consultaItens() {
        const { clickMenu } = this.props;
        const { filialUsuario } = this.state;
        this.setState({ loadingRecarregar: true });
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/equipamentos',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            if (body.mensagem === undefined) {
                let data = [];
                
                if (filialUsuario === "CORPORATIVO" || filialUsuario === '' || filialUsuario === undefined ) {
                    data = response.body;
                } else {
                    data = response.body.filter(function (x) {
                        return x.revenda === filialUsuario
                    })
                }
                for (var g = 0; g < data.length; g++) {
                    data[g].img = saveIcon
                }
                this.setState({ data: data })
                NotificationManager.info('Dados Recarregados');
                this.setState({ loadingRecarregar: false });
            } else {
                this.setState({ loadingRecarregar: false });
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }

        }.bind(this));

        
    }

    salvar(e, item) {
        if (item._cell.row.data.DISABLED !== "1") {
            item._cell.row.data.key_usuario = getKeyUsuario();
            if (item._cell.row.data.key === undefined) {
                let options = {
                    method: 'POST',
                    url: 'https://inteligenciaindustrial.com/choppsystem/equipamentos',
                    headers: {
                        'content-type': 'application/json',
                        'x-access-token': getToken()
                    },
                    body: item._cell.row.data,
                    json: true
                };
    
                request(options, function(error, response, body) {
                    this.consultaItens()
                }.bind(this))
            }
            else {
                let options = {
                    method: 'PUT',
                    url: 'https://inteligenciaindustrial.com/choppsystem/equipamentos',
                    headers: {
                        'content-type': 'application/json',
                        'x-access-token': getToken()
                    },
                    body: item._cell.row.data,
                    json: true
                };
    
                request(options, function(error, response, body) {
                    
                    if (body.mensagem !== undefined) {
                        NotificationManager.error(body.mensagem, '');
                    } else {
                        NotificationManager.success('Informações Salvas', '');
                    }
                })
            }
        } else {
            NotificationManager.error('Não é possivel alterar equipamentos que foram movimentados', '');
        }
    }
    
    excluir(){
        let Excluir = this.state.excluir;
        Excluir.key_usuario = getKeyUsuario();
           let options = {
                method: 'DELETE',
                url: 'https://inteligenciaindustrial.com/choppsystem/equipamentos',
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                body: Excluir,
                json: true
            };

            request(options, function(error, response, body) {
                this.setState({open: false});
                NotificationManager.error('Item Excluído', '');
                this.consultaItens();
            }.bind(this))
        
    }

    componentWillMount() {
        
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/filiais',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            let filiais = []
            for (var g = 0; g < data.length; g++) {
                filiais[g] = data[g].abreviatura
            }
            
            var options = {
                method: 'GET',
                url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/' + getKeyUsuario(),
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': getToken()
                },
                json: true
            };
            
            request(options, function(error, response, body){
                let perfilUsuario = body[0].perfil;
                
                let displayAddRevenda = perfilUsuario === 'REVENDA' ? 'none' : '';
                
                this.setState({
                    filiais: filiais, filialUsuario: body[0].filial, displayAddRevenda: displayAddRevenda
                });
                this.consultaItens();
            }.bind(this));
        }.bind(this));

    }
    
    rowClick(e, row) {
        if (row._row.data.DISABLED === '1') {
            NotificationManager.warning('Esse equipamento foi movimentado e não pode ser alterado', '');
        }
    }

    render() {

        let filiais = [];
        let revendas = [];
        let pdvs = [];
        let tipos = [];
        let especificacoes = [];
        let status = [];
        
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/filiais',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                filiais[g] = data[g].abreviatura
            }
        });
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/revendas',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                revendas[g] = data[g].descricao
            }
        });
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                pdvs[g] = data[g].razao_social
            }
        });

        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/tipos',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                tipos[g] = data[g].tipo
            }
        });
        
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/especificacoes',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                especificacoes[g] = data[g].especificacao
            }
        });


        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/status',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            let data = response.body;
            for (var g = 0; g < data.length; g++) {
                status[g] = data[g].status
            }
        });
        

        var data = this.state.data;

        var saveIcon = function(cell, formatterParams) { //plain text value
            if (cell._cell.row.data.DISABLED !== "1") {
                return '<i aria-hidden="true" class="save icon"></i>';
            }
        };
        
        var deleteIcon = function(cell, formatterParams) { //plain text value
            if (cell._cell.row.data.DISABLED !== "1") {
                return '<i aria-hidden="true" class="close icon"></i>';
            }
        };

        const columns = [
            { title: "ATIVO", field: "ativo", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input", editable: this.editar, topCalc: this.count, width:200},
            { title: "SÉRIE", field: "serie", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input", editable: this.editar},
            { title: "FILIAL", field: "filial", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "select", editable: this.editar, editorParams: { values: filiais } },
            { title: "REVENDA", field: "revenda", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "autocomplete", editable: this.editar, editorParams:{values: revendas} },
            { title: "CIDADE REVENDA", field: "cidade", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input", editable: false, formatter: function (cell) { return cell._cell.row.data.cidade === undefined || cell._cell.row.data.cidade === null ? "" : cell._cell.row.data.cidade.toUpperCase() }},
            { title: "PDV", field: "pdv", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "autocomplete",  editable: this.editar, editorParams:{values: pdvs} },
            { title: "TIPO", field: "tipo", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "select", editable: this.editar, editorParams:{values: tipos} },
            { title: "ESPECÍFICAÇÃO", field: "especificacao", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",width:350,  editor: "select", editable: this.editar, editorParams:{values: especificacoes} },
            { title: "CÓDIGO", field: "codigo", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input", editable: this.editar },
            { title: "STATUS", field: "status", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "select", editable: this.editar, editorParams:{values: status} },
            { title: "OBSERVAÇÕES", field: "observacao", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",  editor: "input", editable: this.editar },
            { title: "", align: "center", cellClick: this.salvar.bind(this), formatter: saveIcon, width: 40 },
            { title: "", align: "center", cellClick: this.open.bind(this), formatter: deleteIcon, width: 40 }

        ];

        return (
        <Segment   style={{'padding':'0px',  'margin':'14px'}}> 
          <Segment clearing attached='top' style={{'padding':'10px'}}>
          <Header as='h4' floated='left' style ={{'margin':'0px'}}>
             Equipamentos
          </Header>

          <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white', display: this.state.displayAddRevenda }}  labelPosition='left' onClick={this.adicionar.bind(this)} loading={this.state.loadingAdicionar}><Icon name='plus' />Adicionar Equipamentos</Button>
          <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  labelPosition='left' onClick={this.consultaItens.bind(this)} loading={this.state.loadingRecarregar}><Icon name='refresh' />Recarregar</Button>  
          <Button floated='right' size='mini' icon style={{backgroundColor:'rgb(0,102,102)', color:'white'}}  onClick={this.exportar}><Icon name='file excel' loading={this.state.loadingExportar} /></Button>
         
        </Segment>
        <Segment attached='bottom' style={{'padding':'0px', 'margin':'0px'}}>
         <ReactTabulator 
           data={data}
           style={{margin:'0px', fontSize:'9px','border':'0px'}}
           options={this.state.options}
           className={'very compact celled'}
           columns={columns}
           tooltips={true}
           layout={"fitColumns"}
           rowClick={this.rowClick}
           />
        </Segment>
         <NotificationContainer/>
         <Modal open={this.state.open} basic size='small'>
            <Header icon='delete' content='Confirmação de Exclusão' />
            <Modal.Content>
              <p>
                Deseja realmente excluir este item?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' inverted onClick={this.close.bind(this)}>
                <Icon name='remove' /> Não Excluir
              </Button>
              <Button color='green' inverted onClick={this.excluir.bind(this)}>
                <Icon name='checkmark' /> Excluir
              </Button>
            </Modal.Actions>
          </Modal>
      </Segment>
        );
    }
}

//export default Equipamentos;

const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Equipamentos);
