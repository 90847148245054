import React from 'react';
import {Image, Menu, Button, Icon, Header } from 'semantic-ui-react';

import img from './../choppsystemlogo.png';

class Header2 extends React.Component {
  render() {
    return (
      <Header textAlign='center' dividing style={{height: '80px'}}>
        <Image size='mini' src={img} style={{marginTop:'15px', marginRight: '0em', width:'175px' }} />
      </Header>
    );
  }
}

export default Header2;
