import React from 'react';
import './App.css';
import "react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css";
import 'semantic-ui-css/semantic.min.css'
import { Modal, Image } from 'semantic-ui-react';
import  Equipamentos  from './componentes/equipamentos/';
import  Filiais  from './componentes/filiais/';
import  Revendas  from './componentes/revendas/';
import  Pdvs  from './componentes/pdvs/';
import  Tipos  from './componentes/tipos/';
import  Especificacoes  from './componentes/especificacoes/';
import  Status  from './componentes/status/';
import  Movimentacoes  from './componentes/movimentacoes/';
import  Usuario from './componentes/usuarios/';
import  AlterarSenha from './componentes/alterarsenha/'
import  Header from './ui/header';
import  Footer from './ui/footer';
import  Login from './ui/login';
import img from './cidade_imperial_logo.png';
import { connect } from 'react-redux';

class App extends React.Component {
  render() {
  const { menu } = this.props;
  
  

  if(menu === 'Equipamentos'){
      return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Equipamentos/>
        </div>
        <Footer/>
      </div>
    );
  } else if(menu === 'Especificacoes'){
      return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Especificacoes/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'Tipos'){
    return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Tipos/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'Status'){
        return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Status/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'Unidades Fabris'){
        return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Filiais/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'Revendas'){
        return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Revendas/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'PDVS'){
        return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Pdvs/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'Movimentacao'){
        return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        <Movimentacoes/>
        </div>
        <Footer/>
      </div>
    );
    
  }else if(menu === 'Inicio'){
    return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
        </div>
        <Footer/>
      </div>
    );
  }else if(menu === 'Usuarios'){
    return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
          <Usuario/>
        </div>
        <Footer/>
      </div>
    );
  }else if(menu === 'AlterarSenha'){
    return (
      <div className="App">
        <Header/>
        <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
          <AlterarSenha/>
        </div>
        <Footer/>
      </div>
    );
  }else if((menu === 'Sair' && localStorage.getItem("token") === null) || menu === 'FECHAR'){
    localStorage.clear();
    return (
        <div className="App">
          <Login/>
        </div>
      );
  }else{
    let token = localStorage.getItem("token");
    if(token === '' || token === null){
      return (
        <div className="App">
          <Login/>
        </div>
      );
      
    }else{
      return (
        <div className="App">
          <Header/>
          <div style={{ marginTop: '0em', marginBottom: '7em', marginLeft:'10px',marginRigth:'10px' }}>
          </div>
          <Footer/>
        </div>
      );
    }
  }
}
}

const mapStateToProps = store => ({
  menu: store.clickMenu.menu
});

export default connect(mapStateToProps)(App);

