import { UPDATE_MENU } from '../_actions/actionTypes';

const initialState = {
  menu: 'STATUS'
};
export const menu = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_MENU':
      return {
        ...state,
        menu: action.menu
      };
    default:
      return state;
  }
};