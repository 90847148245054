/* eslint no-eval: 0 */

import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import { Button, Icon, Header, Segment, Modal, Grid, Select, Form, TextArea, Input } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getToken, getKeyUsuario} from '../../servicos/autenticacao';
import saveIcon from '../../img/save_icon.png';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenu } from './../../_actions';

var request = require("request");
const tiposMovimentacao = [
  { key: 'filial', value: 'filial', text: 'Filial' },
  { key: 'revenda', value: 'revenda', text: 'Revenda' },
  { key: 'pdv', value: 'pdv', text: 'PDV' },
  { key: 'evento', value: 'evento', text: 'Evento' },
  { key: 'manutencao', value: 'manutencao', text: 'Manutenção' }
]
class Movimentacoes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open:false, 
                      data: [], 
                      filiais:[], 
                      revendas:[], 
                      pdvs:[], 
                      status:[], 
                      dataTo:[], 
                      excluir:[], 
                      loadingAdicionar: false, 
                      loadingExportar: false, 
                      loadingRecarregar: false, 
                      optionsTo: {height:'250px' },
                      optionsFrom: { height:'250px' },
                      filial:'',
                      revenda:'',
                      pdv:'',
                      statusMovimentacao:'',
                      dataMovimentacao:'',
                      nf:'',
                      observacao:'',
                      filialUsuario: '',
                      desabilita_filial: true,
                      desabilita_revenda: true,
                      desabilita_pdv: true,
                      desabilita_observacao: true,
                      regraMovimentacao: []
            
        };
    }
    
     open(e, item){ this.setState({ open: true , excluir:item._cell.row.data})};
     close(e, item){ this.setState({ open: false , excluir:[]})};
     
    count = function(values, data, calcParams) {
        var calc = data.length;
        return "TOTAL DE EQUIPAMENTOS: " + calc;
    }

    exportar() {
        window.open("http://inteligenciaindustrial.com:4000/choppsystem/excel/");
    }

    consultaItens() {
        this.setState({ loadingRecarregar: true })
        const { clickMenu } = this.props;
        const { filialUsuario } = this.state;

        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/equipamentos',
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };

        request(options, function(error, response, body) {
            if (body.mensagem === undefined) {
                let data = [];
                if (filialUsuario === "CORPORATIVO" || filialUsuario === '' || filialUsuario === undefined ) {
                    data = response.body;
                } else {
                    data = response.body.filter(function (x) {
                        return x.revenda === filialUsuario
                    })
                }
                this.setState({ data: data })
                NotificationManager.info('Lista de Ativos Carregados');
                //this.setState({ loadingRecarregar: false })
                
                     var options = {
                        method: 'GET',
                        url: 'https://inteligenciaindustrial.com/choppsystem/filiais',
                        headers: {
                            'content-type': 'application/json',
                            'x-access-token': getToken()
                        },
                        json: true
                    };
            
                    request(options, function(error, response, body) {
                        let data = response.body;
                        let dataFilial = [];
                        
                        dataFilial.push({ key: '', value: '', text: ''  });
                        
                        for(let x = 0; x<data.length; x++){
                            dataFilial.push({ key: data[x].abreviatura, value: data[x].abreviatura, text: data[x].descricao  });
                        }
        
                        this.setState({ filiais: dataFilial })
                        NotificationManager.info('Filiais Carregadas');
                        //this.setState({ loadingRecarregar: false })
                        var options = {
                            method: 'GET',
                            url: 'https://inteligenciaindustrial.com/choppsystem/revendas',
                            headers: {
                                'content-type': 'application/json',
                                'x-access-token': getToken()
                            },
                            json: true
                        };
                
                        request(options, function(error, response, body) {
                            let data = response.body;
                            let dataRevendas = [];
    
                            dataRevendas.push({ key: '', value: '', text: ''  });
    
                            for(let x = 0; x<data.length; x++){
                                dataRevendas.push({ key: data[x].descricao, value: data[x].descricao, text: data[x].descricao  });
                            }
            
                            this.setState({ revendas: dataRevendas })
                            NotificationManager.info('Revendas Carregadas');
                            //this.setState({ loadingRecarregar: false })
                            var options = {
                                method: 'GET',
                                url: 'https://inteligenciaindustrial.com/choppsystem/pdvs',
                                headers: {
                                    'content-type': 'application/json',
                                    'x-access-token': getToken()
                                },
                                json: true
                            };
                    
                            request(options, function(error, response, body) {
                                let data = response.body;
                                let dataPDV = [];
                                
                                dataPDV.push({ key: '', value: '', text: ''  });
                                
                                for(let x = 0; x<data.length; x++){
                                    dataPDV.push({ key: data[x].nome_fantasia, value: data[x].nome_fantasia, text: data[x].nome_fantasia  });
                                }
                
                                this.setState({ pdvs: dataPDV })
                                NotificationManager.info('PDVs Carregados');
                                //this.setState({ loadingRecarregar: false })
                                var options = {
                                    method: 'GET',
                                    url: 'https://inteligenciaindustrial.com/choppsystem/status',
                                    headers: {
                                        'content-type': 'application/json',
                                        'x-access-token': getToken()
                                    },
                                    json: true
                                };
                        
                                request(options, function(error, response, body) {
                                    let data = response.body;
                                    let dataStatus = [];
    
                                    dataStatus.push({ key: '', value: '', text: ''  });
    
                                    for(let x = 0; x<data.length; x++){
                                        dataStatus.push({ key: data[x].status, value: data[x].status, text: data[x].status  });
                                    }
                    
                                    this.setState({ status: dataStatus })
                                    NotificationManager.info('Status Carregados');
                                    this.setState({ loadingRecarregar: false });
                                }.bind(this));
                            }.bind(this));
                        }.bind(this));
                    }.bind(this));
            } else {
                this.setState({ loadingRecarregar: false })
                NotificationManager.error("Token Inválido. Você será redirecionado para tela de Login.");
                localStorage.clear();
                setInterval(function(){ clickMenu('') }, 6000);
            }
        }.bind(this));
    }

    adicionaParaMovimentacao(e, row){
        let dataTo = this.state.dataTo;
        dataTo.push(row._row.data);
        
        let dataFrom = this.state.data;
        for( var i = 0; i < dataFrom.length; i++){ 
           if ( dataFrom[i].key === row._row.data.key) {
             dataFrom.splice(i, 1); 
           }
        }

        this.setState({dataTo:dataTo, data:dataFrom});

    }

    removeParaMovimentacao(e, row){
        let dataFrom = this.state.data;
        dataFrom.push(row._row.data);
        
        let dataTo = this.state.dataTo;
        for( var i = 0; i < dataTo.length; i++){ 
           if ( dataTo[i].key === row._row.data.key) {
             dataTo.splice(i, 1); 
           }
        }

        this.setState({dataTo:dataTo, data:dataFrom});

    }


    salvar() {
        
        let movimentar = {
                          filial:this.state.filial,
                          revenda:this.state.revenda,
                          pdv:this.state.pdv,
                          status:this.state.statusMovimentacao,
                          data:this.state.dataMovimentacao,
                          nf:this.state.nf,
                          observacao:this.state.observacao
        }
        
        const { regraMovimentacao } = this.state;
        
        if(this.state.statusMovimentacao.length <= 3){
            NotificationManager.warning('Selecione o Status do Ativo');
        }
        
        let movimentacaoAtual = this.state.statusMovimentacao;
        if(this.state.statusMovimentacao !== ''){
            let regra = regraMovimentacao.filter(function(x){
                return x.status === movimentacaoAtual
            });
            regra = regra[0];
            
            if(this.state.dataTo.length === 0){
                NotificationManager.warning('Selecione pelo menos um ativo para realizar a movimentação.');
            } else
            
            if(this.state.dataMovimentacao.match(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/) === null){
                NotificationManager.warning('Prencha a data no formato adequado.');
            } else
            
            if(this.state.nf.length === 0){
                NotificationManager.warning('Informe o número da Nota Fiscas.');
            } else
            
            if(regra.desabilita_filial === 'false' && this.state.filial.length < 2){
                NotificationManager.warning('Selecione a Filial do Ativo');
            } else
            
            if(regra.desabilita_revenda === 'false' && this.state.revenda.length < 2){
                NotificationManager.warning('Selecione a Revenda do Ativo');
            } else
            
            if(regra.desabilita_pdv === 'false' && this.state.pdv.length < 2){
                NotificationManager.warning('Selecione o PDV do Ativo');
            } else
            
            if((movimentacaoAtual === 'DOAÇÃO' || movimentacaoAtual === 'INATIVADO' ||
                movimentacaoAtual === 'EVENTO' || movimentacaoAtual === 'MANUTENÇÃO') && this.state.observacao.length < 2){
                NotificationManager.warning('Obrigatório informar uma observação para a movimentação do Ativo');
            } else {
                for(var x = 0; x<this.state.dataTo.length;x++){
                    
                    movimentar.key_equipamento = this.state.dataTo[x].key;
                    movimentar.key_usuario = getKeyUsuario()
                    var options = {
                        method: 'POST',
                        url: 'https://inteligenciaindustrial.com/choppsystem/movimentacoes',
                        headers: {
                            'content-type': 'application/json',
                            'x-access-token': getToken()
                        },
                        body: movimentar,
                        json: true
                    };
                    
        
                    request(options, function(error, response, body) {
                      //  this.consultaItens()
                    // NotificationManager.success('Ativo: ' + this.state.dataTo[x].ativo +'da Série:'+this.state.dataTo[x].serie+' movimentado.');
        
                      
                      if(x === (this.state.dataTo.length)){
                          NotificationManager.success('Movimentações Executadas!');
                          this.setState({ filial:'',
                                          revenda:'',
                                          pdv:'',
                                          statusMovimentacao:'',
                                          dataMovimentacao:'',
                                          nf:'',
                                          observacao:'',
                                          dataTo:[],
                                          desabilita_filial: true,
                                          desabilita_revenda: true,
                                          desabilita_pdv: true,
                                          desabilita_observacao: true,
                                          filial: '',
                                          revenda: '',
                                          pdv: '',
                                          observacao: ''
                                         });
                        this.consultaItens();
                      }
                    }.bind(this))
                }
            }
        }
    }

    componentWillMount() {
        var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/choppsystem/usuarios/' + getKeyUsuario(),
            headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
            },
            json: true
        };
        request(options, function(error, response, body){
            var options = {
                method: 'GET',
                url: 'https://inteligenciaindustrial.com/choppsystem/regramovimentacao',
                headers: {
                    'content-type' : 'application/json',
                    'x-access-token' : getToken()
                },
                json: true
            };
            
            let result = body;
            
            request(options, function(error, response, body){
                this.setState({
                    filialUsuario: result[0].filial,
                    regraMovimentacao: body
                });
                this.consultaItens();
            }.bind(this));
        }.bind(this));
    }
    
    altera(a,{value, name}){
        this.setState({[name]:value})
    }
    
    alteraStatus(a,{value, name}){
        const { regraMovimentacao } = this.state;
        //console.log(value);
        //console.log(regraMovimentacao);
        let regra = regraMovimentacao.filter(function(x){
            return x.status === value
        });
        
        if (regra.length >= 1) {
            if (value !== '') {
                let desabilita_filial = regra[0].desabilita_filial === 'true' ? true: false,
                    desabilita_revenda = regra[0].desabilita_revenda === 'true' ? true : false,
                    desabilita_pdv = regra[0].desabilita_pdv === 'true' ? true : false,
                    desabilita_observacao = regra[0].desabilita_observacao === 'true' ? true : false
                this.setState({
                    [name]:value,
                    desabilita_filial,
                    desabilita_revenda,
                    desabilita_pdv,
                    desabilita_observacao,
                    filial: '',
                    revenda: '',
                    pdv: '',
                    observacao: ''
                });
            }
        } else {
            if (value === '') {
                this.setState({
                    [name]:value,
                    desabilita_filial: true,
                    desabilita_revenda: true,
                    desabilita_pdv: true,
                    desabilita_observacao: true,
                    filial: '',
                    revenda: '',
                    pdv: '',
                    observacao: ''
                });
            } else {
                NotificationManager.error("Regra não encontrada para o status selecionado");
            }
        }
    }

    render() {
        var data = this.state.data;

        var move = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="arrow right icon"></i>';
        };
        
         var deleteIcon = function(cell, formatterParams) { //plain text value
            return '<i aria-hidden="true" class="close icon"></i>';
        };

        const columns = [{title:"LISTA DE EQUIPAMENTOS",
                            columns:[
                                    { title: "ATIVO", field: "ativo", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",   width:200},
                                    { title: "SÉRIE", field: "serie", align: "center", headerFilter: "input", headerFilterPlaceholder: " "   },
                                    { title: "FILIAL", field: "filial", align: "center", headerFilter: "input", headerFilterPlaceholder: " "   },
                                    { title: "REVENDA", field: "revenda", align: "center", headerFilter: "input", headerFilterPlaceholder: " " },
                                    { title: "CIDADE REVENDA", field: "cidade", align: "center", headerFilter: "input", headerFilterPlaceholder: " ", formatter: function (cell) { return cell._cell.row.data.cidade === undefined || cell._cell.row.data.cidade === null ? "" : cell._cell.row.data.cidade.toUpperCase() }},
                                    { title: "PDV", field: "pdv", align: "center", headerFilter: "input", headerFilterPlaceholder: " " },
                                    { title: "TIPO", field: "tipo", align: "center", headerFilter: "input", headerFilterPlaceholder: " " },
                                    { title: "ESPECÍFICAÇÃO", field: "especificacao", align: "center", headerFilter: "input", headerFilterPlaceholder: " ",width:350},
                                    { title: "CÓDIGO", field: "codigo", align: "center", headerFilter: "input", headerFilterPlaceholder: " "},
                                    { title: "STATUS", field: "status", align: "center", headerFilter: "input", headerFilterPlaceholder: " "}]
                            }];

        const columnsTo = [{title:"EQUIPAMENTOS À MOVIMENTAR",
                            columns:[
                                { title: "ATIVO", field: "ativo", align: "center",   width:200},
                                { title: "SÉRIE", field: "serie", align: "center"   },
                                { title: "FILIAL", field: "filial", align: "center"  },
                                { title: "REVENDA", field: "revenda", align: "center" },
                                { title: "CIDADE REVENDA", field: "cidade", align: "center", formatter: function (cell) { return cell._cell.row.data.cidade.toUpperCase() }},
                                { title: "PDV", field: "pdv", align: "center",  },
                                { title: "TIPO", field: "tipo", align: "center" },
                                { title: "ESPECÍFICAÇÃO", field: "especificacao", align: "center",width:350},
                                { title: "CÓDIGO", field: "codigo", align: "center"},
                                { title: "STATUS", field: "status", align: "center"}
                                ]
                            }];
        return (
        <Segment  style={{'padding':'0px', 'margin':'14px'}}> 
        <Segment clearing attached='top' style={{'padding':'10px'}}>
          <Header as='h4' floated='left' style ={{'margin':'0px'}}>
             <Icon name='exchange' size='small' />
             Movimentações
          </Header>
        </Segment>
        
          <Grid columns={2} style={{width:'100%'}}>
            <Grid.Row stretched style={{width:'100%'}}>
              <Grid.Column width={12}>
                <Segment  attached basic  style={{'padding':'0px', margin:'3px !important', width:'100%'}}>
                 <ReactTabulator 
                   id='from'
                   data={data}
                   style={{margin:'0px', fontSize:'9px','border':'0px',width:'100%'}}
                   options={this.state.optionsTo}
                   className={'very compact celled'}
                   columns={columns}
                   rowDblClick={this.adicionaParaMovimentacao.bind(this)}
                   tooltips={true}
                   layout={"fitColumns"}
                   />
                </Segment>
                <Segment  attached basic style={{'padding':'0px',  margin:'3px !important', width:'100%'}}>
                 <ReactTabulator 
                   id="to"
                   data={this.state.dataTo}
                   style={{margin:'0px', fontSize:'9px','border':'0px',width:'100%'}}
                   options={this.state.optionsFrom}
                   className={'very compact celled'}
                   columns={columnsTo}
                   tooltips={true}
                   rowDblClick={this.removeParaMovimentacao.bind(this)}
                   layout={"fitColumns"}
                   />
                </Segment>
                
              </Grid.Column>
              <Grid.Column width={4}>
                <Segment basic style={{textAlign: 'left'}}>
                      <Form>
                        <Form.Field label='Data da Movimentação' control={Input} placeholder='DD/MM/AAAA' name='dataMovimentacao' onChange={this.altera.bind(this)} value={this.state.dataMovimentacao}></Form.Field>
                        <Form.Field label='NF' control={Input}  placeholder='Nota Fiscal' name='nf' onChange={this.altera.bind(this)} value={this.state.nf}></Form.Field>
                        <Form.Field label='Status' control={Select} placeholder='Selecione um Status' name='statusMovimentacao' options={this.state.status} onChange={this.alteraStatus.bind(this)} value={this.state.statusMovimentacao}></Form.Field>
                        <Form.Field id='desabilita_filial' label='Filial' control={Select} placeholder='Selecione uma filial' name='filial' options={this.state.filiais} onChange={this.altera.bind(this)} value={this.state.filial} disabled={this.state.desabilita_filial}></Form.Field>
                        <Form.Field label='Revenda' control={Select} placeholder='Selecione uma revenda' name='revenda' options={this.state.revendas} onChange={this.altera.bind(this)} value={this.state.revenda} disabled={this.state.desabilita_revenda}></Form.Field>
                        <Form.Field label='PDV' control={Select} placeholder='Selecione um pdv' name='pdv' options={this.state.pdvs} onChange={this.altera.bind(this)} value={this.state.pdv} disabled={this.state.desabilita_pdv}></Form.Field>
                        <Form.Field label='Observações' control={TextArea} placeholder='Observações' name='observacao' onChange={this.altera.bind(this)} value={this.state.observacao} disabled={this.state.desabilita_observacao}></Form.Field>
                        <Button icon='exchange' onClick={this.salvar.bind(this)} content='Movimentar' style={{background:'rgb(0, 102, 102)', width:'100%', color:'white'}} labelPosition='right'/>
                      </Form>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
               <NotificationContainer/>
                <Modal open={this.state.open} basic size='small'>
                    <Header icon='delete' content='Confirmação de Movimentação' />
                    <Modal.Content>
                      <p>
                        Deseja confirmar a movimentação?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic color='red' inverted onClick={this.close.bind(this)}>
                        <Icon name='remove' /> Cancelar Movimentação
                      </Button>
                      <Button color='green' inverted >
                        <Icon name='checkmark' /> Confirmar Movimentação
                      </Button>
                    </Modal.Actions>
                  </Modal>
      </Segment>
        );
    }
}

const mapStateToProps = store => ({
  menu: store.clickMenu.newValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenu }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Movimentacoes);
